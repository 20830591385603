/* eslint-disable react/require-default-props */
import { Link } from 'gatsby';
import React from 'react';
import Divider from '@/components/divider';
import { menuItemsArray } from '@/config';
import './layout.css';

export interface LayoutProps {
  children: React.ReactNode;
  activeMenu?: MenuItem;
  baseLink?: string;
  menuItems?: Array<MenuItem>;
}

export interface MenuItem {
  uri: string;
  label: string;
}

const activeMenuItems = (length: number, activeItem: MenuItem) => {
  let ret = [activeItem];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length - 1; i++) {
    ret = [...ret, { uri: `placeholder ${i}`, label: `placeholder` }];
  }
  return ret;
};

export default function Layout({
  children,
  activeMenu: activeItem,
  menuItems = menuItemsArray,
  baseLink = `/`,
}: LayoutProps) {
  const isMenuActive = Boolean(activeItem);
  const items = isMenuActive
    ? activeMenuItems(menuItemsArray.length, activeItem)
    : menuItemsArray;

  const activeIdx = menuItems.findIndex(({ uri }) => uri === activeItem?.uri);

  const [prev, , next] = isMenuActive ? menuItems.slice(activeIdx - 1) : [];
  const [first] = isMenuActive
    ? menuItems.filter(({ uri }) => uri !== activeItem?.uri)
    : [];

  const [footerOne, footerTwo] = [prev || first, next || first];

  return (
    <>
      <div className="wrapper">
        <nav>
          <ul>
            {items.map((item, idx) => (
              <li key={item.uri}>
                <Link
                  className={isMenuActive && idx !== 0 ? `visually-hidden` : ``}
                  to={item.uri}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <div style={{ display: `flex` }}>
            <Link
              to={baseLink}
              style={{
                minWidth: `100px`,
                maxWidth: `211px`,
                width: `100%`,
              }}
            >
              <Divider style={{ marginTop: `25px`, marginBottom: `25px` }} />
            </Link>
          </div>
        </nav>
        <main>{children}</main>
        <footer>
          {!!footerOne && (
            <div>
              <Divider />
              <div className="content-padding">
                <Link to={footerOne.uri}>{footerOne.label}</Link>
              </div>
            </div>
          )}
          {!!footerTwo && (
            <div
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-end`,
              }}
            >
              <Divider />
              <div className="content-padding">
                <Link to={footerTwo.uri}>{footerTwo.label}</Link>
              </div>
            </div>
          )}
        </footer>
      </div>
      <div id="presentation" role="presentation" />
    </>
  );
}
