/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react';

export interface DividerProps {
  fullWidth?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
}

export default function Divider({ fullWidth, children, style }: DividerProps) {
  return (
    <div className={`divider ${fullWidth ? `full-width` : ``}`} style={style}>
      {children}
    </div>
  );
}
