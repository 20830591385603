import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({
  title,
  description,
  keywords,
}: {
  title: string | null;
  description: string | null;
  keywords: Array<string> | null;
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang: `de-CH`,
      }}
      title={`Givan | ${title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords?.join(`,`),
        },
      ]}
    />
  );
}
