export const menuItems = {
  philosophy: {
    uri: `/philosophie`,
    label: `Philosophie`,
  },
  references: {
    uri: `/referenzen`,
    label: `Referenzen`,
  },
  contact: {
    uri: `/kontakt`,
    label: `Kontakt`,
  },
};

export const menuItemsArray = Object.values(menuItems).reduce(
  (acc, curr) => [...acc, curr],
  [],
);
